<template>
  <div style="display: flex">
    <template v-for="item in value">
      <q-chip
        square
        v-if="!nullObjectKey"
        :key="item[objectKey]"
        color="primary"
        :text-color="filterOption.value[item[objectKey]] ? 'white' : 'primary'"
        :outline="!filterOption.value[item[objectKey]]"
      >
        {{ $t(item[objectKey]) }}
      </q-chip>
      <q-chip
        v-else
        square
        :key="item"
        color="primary"
        :text-color="filterOption.value[item] ? 'white' : 'primary'"
        :outline="!filterOption.value[item]"
      >
        {{ item ? $t(item) : $t(emptyContent) }}
      </q-chip>
    </template>
  </div>
</template>

<script>
export default {
  name: "ProReportMultipleChipTdField",
  props: {
    objectKey: {
      type: String,
      default: "value",
    },
    nullObjectKey: {
      type: Boolean,
      default: false,
    },
    emptyContent: null,
    value: null,
    filterOption: {},
  },
};
</script>
